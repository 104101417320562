import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@theme-ui/mdx';
import components from '@components/Mdx';
import { CustomParagraph } from '@widgets/Post'

const isButton = (alt) => [
  'registrati button',
  'sign up button',
  'Sign up now',
  'Regístrate ',
].includes(alt);

export const ImageComponent = (props) => {
  const image = props?.src?.fluidedImage;

  if(!image) return <div />;

  return !isButton(props.alt) && (
    <amp-img
      src={image?.src}
      srcset={image?.srcSet}
      height={image?.presentationHeight || 475}
      width={image?.presentationWidth || 780}
      alt={props.alt}
      class={props.className}
      layout='responsive'
    />
  );
};

export const PostBody = ({ body }) => {
  return (
    <MDXProvider components={{ ...components, img: ImageComponent, p: CustomParagraph }}>
      <MDXRenderer>{body}</MDXRenderer>
    </MDXProvider>
  );
};
