import React from 'react';
import { Layout, Stack, Main } from '@layout';
import Seo from '@widgets/Seo';
import Divider from '@components/Divider';
import Card from '../../flow-ui-components/CardAMP';
import { graphql } from 'gatsby';
import { Card as CardComponent } from 'theme-ui';
import CardList from '@components/CardList';
import { PostImage, PostBody } from '../../flow-ui-widgets/PostAMP';
import { PostTagsShare } from '@widgets/Post';
import AuthorExpanded from '../../flow-ui-widgets/AuthorExpandedAMP';
import FixedButton from '../../../../content/components/FixedButton';
import { Global } from '@emotion/core';

const COUNTRIES = {
  it: 'Italia',
  uk: 'UK',
  es: 'España',
  us: 'US',
  pt: 'Portugal',
};

const Post = (props) => {
  const {
    data: { site, post, tagCategoryPosts, tagPosts, categoryPosts },
  } = props;
  const newPost = { ...post, slug: post.slug + 'amp/' };
  const relatedPosts = [
    ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
    ...(tagPosts ? tagPosts.nodes : []),
    ...(categoryPosts ? categoryPosts.nodes : []),
  ].map((item) => ({ ...item, slug: item.slug + 'amp/' }));
  const {
    locale,
    siteUrl,
    app: { baseUrl },
  } = site.siteMetadata;
  const { pageContext: { services = {} } = {} } = props;
  const content = props?.pageContext?.modifiedData.replaceAll(
    /http[s]?:\/\/app.togetherprice.com/gi,
    baseUrl
  );
  const links = content.match(/"href": "(.*?)"/g);
  const link = links?.pop()?.replaceAll('"href": "', '')?.replaceAll('"', '');
  const { GATSBY_BUILD_LANGUAGE } = process.env;

  return (
    <Layout {...props} amp>
      <Seo
        {...newPost}
        logo={
          props.data.allBlockContent.nodes.find(
            (item) => item.identifier === 'footer'
          ).images[1]
        }
        thumbnail={{
          hero: { src: newPost.thumbnail?.ContentfulAsset_fluid?.src },
        }}
        siteUrl={`${siteUrl}/${locale}`}
        baseUrl={baseUrl}
        appended={COUNTRIES[GATSBY_BUILD_LANGUAGE || locale]}
      />
      <Global
        styles={{
          '.amp-sidebar-mask': {
            zIndex: 0,
          },
          'amp-img img': {
            objectFit: 'cover',
          },
        }}
      />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <Main>
          <Card {...newPost} variant='horizontal-hero' omitExcerpt omitMedia />
        </Main>
      </Stack>
      <Divider space={3} />
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          <CardComponent variant='paper-lg'>
            {post.thumbnail && <PostImage {...newPost} inCard />}
            <PostBody body={content} />
            <PostTagsShare {...post} location={props.location} />
            {/* {services.disqus && <PostComments {...newPost} />} */}
          </CardComponent>
          <Divider />
          <AuthorExpanded author={newPost.author} withLink={false} />
          <Divider />
          {post.category && (
            <CardList
              title='Related Posts'
              nodes={relatedPosts}
              variant={['horizontal-md']}
              columns={[1, 2, 2, 2]}
              limit={6}
              distinct
              amp
            />
          )}
        </Main>
      </Stack>
      {link && <FixedButton href={link} locale={locale} />}
    </Layout>
  );
};

export const pageQuery = graphql`
  query PostAmpPageQuery(
    $id: String!
    $previousId: String
    $nextId: String
    $categoryId: String
    $tagsIds: [String]
    $hasTags: Boolean!
    $blocksPaths: [String]!
  ) {
    site {
      siteMetadata {
        locale
        siteUrl
        app {
          baseUrl
        }
      }
    }
    post: article(id: { eq: $id }) {
      ...ArticleInformation
      ...ArticleThumbnailRegular
    }
    allBlockContent: allBlockContent(
      filter: {
        page: { in: $blocksPaths }
        identifier: { in: ["header", "post-buttons", "footer"] }
      }
    ) {
      nodes {
        ...BlockContent
      }
    }
    # Related posts based on tags and category
    tagCategoryPosts: allArticle(
      filter: {
        private: { ne: true }
        draft: { ne: true }
        tags: { elemMatch: { id: { in: $tagsIds } } }
        category: { id: { eq: $categoryId } }
        id: { ne: $id }
      }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) @include(if: $hasTags) {
      nodes {
        ...ArticlePreview
        ...ArticleThumbnailRegular
      }
    }

    # Related posts based on tags only
    tagPosts: allArticle(
      filter: {
        private: { ne: true }
        draft: { ne: true }
        tags: { elemMatch: { id: { in: $tagsIds } } }
        id: { ne: $id }
      }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) @include(if: $hasTags) {
      nodes {
        ...ArticlePreview
        ...ArticleThumbnailRegular
      }
    }

    # Related posts based on category only
    categoryPosts: allArticle(
      filter: {
        private: { ne: true }
        draft: { ne: true }
        category: { id: { eq: $categoryId } }
        id: { ne: $id }
      }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) {
      nodes {
        ...ArticlePreview
        ...ArticleThumbnailRegular
      }
    }

    previous: article(id: { eq: $previousId }) {
      id
      slug
      title
    }

    next: article(id: { eq: $nextId }) {
      id
      slug
      title
    }
  }
`;
export default Post;
